import React, { useState, useEffect } from 'react';
import { navigate, graphql } from 'gatsby';
import { Footer } from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import { availableBlocks } from './ProjectDetail';

import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import './lightbox.css';
import ReactPlayer from 'react-player/lazy';
import { Helmet } from 'react-helmet';

let mouseTimeout: any;
const FullPageVideo = ({ pageContext: { locale, video360 }, data }: any) => {
  const [{ x, y, scale }, setOffset] = useState({ x: 0, y: 0, scale: 1 });
  const content = video360
    ? data.allContentfulLanding.nodes[0]?.topIntroSection
    : data.allContentfulLanding.nodes[0]?.rightIntroSection;
  const next = data.allContentfulLanding.nodes[0]?.bottomIntroSection;

  useEffect(() => {
    let scaleFactor = Math.max(
      window.innerWidth / 1920,
      window.innerHeight / 1080
    );

    setOffset({
      x: (window.innerWidth - 1920 * scaleFactor) / 2,
      y: (window.innerHeight - 1080 * scaleFactor) / 2,
      scale: scaleFactor,
    });

    const scale = () => {
      scaleFactor = Math.max(
        window.innerWidth / 1920,
        window.innerHeight / 1080
      );
      setOffset({
        x: (window.innerWidth - 1920 * scaleFactor) / 2,
        y: (window.innerHeight - 1080 * scaleFactor) / 2,
        scale: scaleFactor,
      });
    };
    window.addEventListener('resize', scale, false);

    return () => window.removeEventListener('resize', scale, false);
  }, []);

  const [play, setPlay] = useState(true);
  const [controls, setControls] = useState(true);
  const [disable, setDisable] = useState(false);
  const [muted, setMuted] = useState(typeof window !== 'undefined');
  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href="//player.vimeo.com" />
        <title>{`Edge Fx - ${content.title}`}</title>
        <meta name="title" content={`Edge Fx - ${content.title}`} />
        <meta
          name="description"
          content={content.slogan.slogan.replace(/\n/g, ' ')}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://edgefx.tv/${content.slug}`} />
        <meta property="og:title" content={`Edge Fx - ${content.title}`} />
        <meta
          property="og:description"
          content={content.slogan.slogan.replace(/\n/g, ' ')}
        />
        <meta property="og:image" content={content.posterImage.file.url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edgefx.tv" />
        <meta
          property="twitter:url"
          content={`https://edgefx.tv/${content.slug}`}
        />
        <meta name="twitter:title" content="Edge Fx" />
        <meta
          name="twitter:description"
          content={content.slogan.slogan.replace(/\n/g, ' ')}
        />
        <meta name="twitter:image" content={content.posterImage.file.url} />
      </Helmet>
      <SimpleReactLightbox>
        <div className={'intro-container intro-container-scroll'}>
          <div
            className={'chevron-control'}
            onClick={() => {
              navigate(`${locale === 'it' ? '' : '/en'}/#${content.slug}`);
            }}
          >
            <svg
              enableBackground="new 0 0 515.556 515.556"
              height="24"
              viewBox="0 0 515.556 515.556"
              width="24"
            >
              <path
                stroke="#fff"
                fill="#fff"
                d="m128.885 257.778 257.778-257.778v128.886l-128.889 128.892 128.897 128.886-.008 128.892z"
              />
            </svg>
          </div>
          <div
            className={'chevron-scroll'}
            onClick={() => {
              const dt = document.querySelector('.description-title');
              dt?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }}
          >
            <svg
              enableBackground="new 0 0 515.556 515.556"
              height="24"
              viewBox="0 0 515.556 515.556"
              width="24"
            >
              <path
                stroke="#fff"
                fill="#fff"
                d="m128.885 257.778 257.778-257.778v128.886l-128.889 128.892 128.897 128.886-.008 128.892z"
              />
            </svg>
          </div>
          {/* <Icon /> */}
          {typeof window !== 'undefined' && window.innerWidth > 900 && (
            <div
              className={
                typeof window !== 'undefined' && window.innerWidth < 900
                  ? ''
                  : `player-overlay player-overlay-${
                      controls || disable ? 'show' : 'hide'
                    }`
              }
              onMouseMove={() => {
                if (controls) {
                  clearTimeout(mouseTimeout);
                  mouseTimeout = setTimeout(() => setControls(false), 1200);
                } else {
                  setControls(true);
                  setTimeout(() => setControls(false), 1200);
                }
              }}
            >
              {/* {controls ? ( */}

              <svg
                width="24px"
                height="24px"
                viewBox="0 0 60 60"
                onClick={() => setPlay(!play)}
                onMouseOver={() => {
                  setDisable(true);
                  setControls(true);
                }}
                onMouseLeave={() => {
                  setDisable(false);
                }}
              >
                {typeof window !== 'undefined' && window.innerWidth < 900 ? (
                  <></>
                ) : play ? (
                  <>
                    <polygon points="0,0 15,0 15,60 0,60" />
                    <polygon points="25,0 40,0 40,60 25,60" />
                  </>
                ) : (
                  <>
                    <polygon points="0,0 50,30 0,60" />
                  </>
                )}
              </svg>
              {typeof window !== 'undefined' && window.innerWidth > 900 && (
                <svg
                  width="36px"
                  height="24px"
                  viewBox="0 0 102 92"
                  onClick={() => setMuted(!muted)}
                  onMouseOver={() => {
                    setDisable(true);
                    setControls(true);
                  }}
                  onMouseLeave={() => {
                    setDisable(false);
                  }}
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="1"
                  >
                    <g
                      id="player"
                      transform="translate(-995.000000, -474.000000)"
                    >
                      <g
                        id="Group-5"
                        transform="translate(995.000000, 474.000000)"
                      >
                        <path
                          d="M24.6226415,28.75 L60,1.40957468e-10 L60,92 L24.6226415,63.25 L0,63.25 L0,28.75 L24.6226415,28.75 Z"
                          id="Combined-Shape"
                          fill="#FFFFFF"
                        ></path>
                        {!muted && (
                          <>
                            <path
                              d="M62.5469888,36.1920573 C62.5648568,50.1469934 73.8920526,61.4741893 87.8469888,61.4920573 L87.8469888,61.4920573"
                              id="Path"
                              stroke="#FFFFFF"
                              strokeWidth="6"
                              transform="translate(75.196989, 48.842057) scale(-1, 1) rotate(-315.000000) translate(-75.196989, -48.842057) "
                            ></path>
                            <path
                              d="M65.8522918,28.1420573 C65.8815304,50.9774073 84.4169418,69.5128187 107.252292,69.5420573 L107.252292,69.5420573"
                              id="Path-Copy"
                              stroke="#FFFFFF"
                              strokeWidth="6"
                              transform="translate(86.552292, 48.842057) scale(-1, 1) rotate(-315.000000) translate(-86.552292, -48.842057) "
                            ></path>
                          </>
                        )}
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </div>
          )}

          <div className={'detail-page detail-page-intro'}>
            {typeof window !== 'undefined' && window.innerWidth < 900 ? (
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  transformOrigin: '0 0',
                  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 59%, rgba(0, 0, 0, 0.55) 100%), url(${content.posterImageMobile.file.url}`,
                  width: '100vw',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '100vh',
                  minHeight: '360px',
                }}
              >
                <div className={'slogan-mobile'}>
                  {content.slogan?.slogan && (
                    <ReactMarkdown
                      source={content.slogan?.slogan}
                      escapeHtml={false}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                style={
                  typeof window !== 'undefined' && window.innerWidth < 900
                    ? {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        transformOrigin: '0 0',
                      }
                    : {
                        position: 'absolute',
                        left: x,
                        top: y,
                        transform: `scale(${scale})`,
                        transformOrigin: '0 0',
                      }
                }
              >
                <div className="player-wrapper">
                  <ReactPlayer
                    className="react-player"
                    url={content.videoId}
                    width={
                      typeof window !== 'undefined' && window.innerWidth < 900
                        ? '100vw'
                        : '1920px'
                    }
                    height={
                      typeof window !== 'undefined' && window.innerWidth < 900
                        ? '100vh'
                        : '1080px'
                    }
                    playing={play}
                    volume={1}
                    muted={muted}
                    controls={
                      typeof window !== 'undefined' && window.innerWidth < 900
                    }
                    loop
                  />
                </div>
              </div>
            )}
            <div className={'description-intro-container'}>
              <div className={'description-title description-title-intro'}>
                <h1>{content.title}</h1>
              </div>
              {typeof window !== 'undefined' && window.innerWidth < 900 && (
                <ReactPlayer
                  {...(video360 && { style: { marginBottom: '12px' } })}
                  className="react-player"
                  url={content.videoId}
                  width={
                    typeof window !== 'undefined' && window.innerWidth < 900
                      ? '100vw'
                      : '1920px'
                  }
                  height={
                    typeof window !== 'undefined' && window.innerWidth < 900
                      ? '100vh'
                      : '1080px'
                  }
                  playing={false}
                  volume={1}
                  muted={muted}
                  controls={
                    typeof window !== 'undefined' && window.innerWidth < 900
                  }
                  loop
                  onPlay={() => {
                    setControls(false);
                  }}
                />
              )}
              <div className={'description-intro'}>
                {content.spoiler?.spoiler && (
                  <ReactMarkdown
                    source={content.spoiler?.spoiler}
                    escapeHtml={false}
                  />
                )}
              </div>
            </div>

            <SRLWrapper>
              <div className={'blocks-container'}>
                {content &&
                  content.content &&
                  content.content.map((block: any, index: number) => (
                    <React.Fragment key={`block-${index}`}>
                      {block.slogan && (
                        <div className={'block-slogan'}>
                          <ReactMarkdown
                            source={block.slogan.slogan}
                            escapeHtml={false}
                          />
                        </div>
                      )}
                      {!!block?.blocks?.length && (
                        <div
                          className={`detail-block ${
                            block.blocks.length > 1
                              ? 'detail-block-fullpagevideo'
                              : ''
                          }`}
                        >
                          {block.blocks.map((el: any, idx: number) => {
                            const Renderer = availableBlocks[el.__typename];

                            return (
                              <React.Fragment
                                key={`block-renderer-${index}-${idx}`}
                              >
                                <div
                                  className={`block-${
                                    block.blocks.length > 1
                                      ? 'fullpagevideo'
                                      : 'single'
                                  }`}
                                >
                                  <Renderer
                                    key={`${idx}-${index}`}
                                    block={el}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </SRLWrapper>

            <Footer locale={locale} next={next} />
          </div>
        </div>
      </SimpleReactLightbox>
    </>
  );
};

export default FullPageVideo;

export const query = graphql`
  query($locale: String!) {
    allContentfulLanding(filter: { node_locale: { eq: $locale } }) {
      nodes {
        topIntroSection {
          title
          slug
          slogan {
            slogan
          }
          videoId
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
          spoiler {
            spoiler
          }
          content {
            ...ProjectBlockFragment
          }
        }
        rightIntroSection {
          title
          slug
          slogan {
            slogan
          }
          videoId
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
          spoiler {
            spoiler
          }
          content {
            ...ProjectBlockFragment
          }
        }
        bottomIntroSection {
          title
          slug
          posterImage {
            file {
              url
            }
          }
          posterImageMobile {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
