import { Link } from 'gatsby';
import React from 'react';
import { SharingIcons } from './SharingIcons';
import { useLocation } from '@reach/router';

export const Footer = ({ locale, next }) => {
  const location = useLocation();
  return (
    <div className={'footer'}>
      <h2 className={'footer-title'}>
        {locale === 'it' ? 'Adesso che succede?!' : 'This is how we do it!'}
      </h2>
      <p className={'footer-paragraph'}>
        {locale === 'it' ? (
          <>
            Te lo spieghiamo in 55 caratteri:
            <br />
            Noi creiamo nuovi mondi e incredibili interconnessioni.
          </>
        ) : (
          `We create new worlds and incredible interconnections.`
        )}
      </p>
      <div className={'footer-button'}>
        <a href={'mailto:info@edgefx.tv'}>
          <button className="btn">
            <svg
              width="350px"
              height="70px"
              viewBox="0 0 350 70"
              className="border"
            >
              <polyline
                points="349,1 349,59 1,59 1,1 349,1"
                className="hl-line"
              />
            </svg>
            <span>
              {locale === 'it' ? 'CONNETTIAMOCI ORA!' : "LET'S CONNECT NOW!"}
            </span>
          </button>
        </a>
      </div>
      <Link to={`${locale === 'it' ? '' : `/${locale}`}/${next.slug}`}>
        <div
          className={'footer-next-project'}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 59%, rgba(0, 0, 0, 0.95) 100%), url(${next.posterImage?.file.url}`,
          }}
        >
          <p>{next.title?.toLocaleUpperCase()}</p>
        </div>
      </Link>
      <div className={'logo-footer'}>
        <Link to={`/${location.pathname.includes('en') ? 'en' : ''}`}>
          <img src={'/LOGO.svg'} alt={'logo'} />
        </Link>
      </div>
      <div className={'footer-contact-block'}>
        <h3>General Inquires</h3>
        <a href={'mailto:info@edgefx.tv'}>
          <p>info@edgefx.tv</p>
        </a>
        {/* <h3>Address</h3>
        <p>info@edgefxstudios.com</p> */}

        <h3>Telephone</h3>
        <a href={'tel:+393403153593'}>
          <p>+39 3403153593</p>
        </a>

        <h3>Social</h3>
        <SharingIcons />

        <p className={'footer-copyright'}>
          All rights reserved © 2021 P.Iva: 11990330018
        </p>
      </div>
    </div>
  );
};
